import {motion} from "framer-motion";
// import Layout from "../components/Layout";

import { workData } from "../data/workData";

import "../styles/work.scss";
import { Link } from "react-router-dom";

function Work() {

    return (
        // <Layout>
            <motion.div
                className="main-section"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                {workData.map((item, i) => {
                    return <div key={i} className="work">
                        <h3>{item.title}</h3>
                        {item.works.map((work, i) => {
                            return <>
                                {work instanceof Object ?
                                    <>
                                        {'link' in work ? 
                                            <Link to={work.link} key={i} target="_blank" rel="noreferrer"><i>{work.title}</i> - {work.details}</Link>
                                            : 
                                            <div><i>{work.title}</i> - {work.details}</div>}
                                    </>
                                    :
                                    <div key={i}><i>{work}</i></div>
                                }
                            </>
                        })}
                    </div>
                })}
            </motion.div>
        // </Layout>
    );
}
  
export default Work;