import {motion} from "framer-motion";
// import Layout from "../components/Layout";

import "../styles/home.scss";

function Home() {

    return (
        // <Layout>
            <motion.div
                className=""
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
            </motion.div>
        // </Layout>
    );
}
  
export default Home;