export const workData = [
    {
        title: "PRODUCER",
        works: [
            {
                title: "OKA Production",
                details: "in-house producer / 50+ credits available upon request",
            },
            {
                title: "Mia and Winona",
                details: "Sofia Del Carmen / short film",
            },
            {
                title: "After a Long Period of Neglect",
                details: "Sofia Del Carmen / short film",
            },
        ],
    },
    {
        title: "PRODUCTION ASSISTANT",    
        works: [
            {
                title: "Dead or Alive",
                details: "Feux / Owen Kasparian / VANTA Paris",
                link: "https://www.youtube.com/watch?v=ajrsJ7Q-pEs"
            },
            {
                title: "Lightbeams",
                details: "Feux / Owen Kasparian / VANTA Paris",
                link: "https://www.youtube.com/watch?v=Z7jwUjTRdIg"
            },
            {
                title: "Diamond Life",
                details: "Leo Kaylan ft. MNEK / Jade Jackman / FRIEND London",
                link: "https://www.youtube.com/watch?v=Fa9JfgCRj5Q"
            },
            {
                title: "Antisocial",
                details: "Feux / Owen Kasparian / VANTA Paris",
                link: "https://m.youtube.com/watch?v=jyn4nSfsX7Q"
            },
            {
                title: "Who’s Loving You?",
                details: "Hamzaa / Loona Riia Kasemets / RANKIN London",
            },
            {
                title: "Bodies",
                details: "Sipho / Loona Riia Kasemets / RANKIN London",
                link: "https://www.youtube.com/watch?v=9NtSnzZLBaQ"
            },
            {
                title: "O Stranger",
                details: "Sundara Karma / Loona Riia Kasemets / THE GRAFT London",
                link: "https://www.youtube.com/watch?v=sNG6BZFIjys"
            },
            {
                title: "La Primavera",
                details: "The Indios / Loona Riia Kasemets / RANKIN London",
                link: "https://www.youtube.com/watch?v=wucVZAANAzg"
            },
        ],
    },
    {
        title: "DIRECTOR",
        works: [
            {
                title: "Polarised (2022)",
                details: "Prod. Karla Vazquez / Guanajuato International Film Festival, Paris Film Awards"
            },
            {
                title: "Act Like Adults (2022)",
                details: "Prod. Karla Vazquez"
            }
        ],
    },
    {
        title: "BBC PRODUCTION TEAM",
        works: [
            "Hungry For It - S1",
            "Antiques Roadshow -  S44 - S45",
            "The Invictus Games 2022",
            "The Queen’s Platinum Jubilee Pageant 2022",
            "Festival of Remembrance 2021",
            "Top Gear - S31",
        ],
    },
    {
        title: "RESEARCHER",
        works: [
            {
                title: "Stacey Dooley Sleeps Over S2",
                details: "Firecracker Films",
            }
        ],
    },
];
