import {motion} from "framer-motion";
import { Link } from "react-router-dom";

import "../styles/contact.scss";

function Contact() {

    return (
        // <Layout>
            <motion.div
                className="main-section contact"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div>Please enquire for bookings & full CV.</div>

                <div className="socials">
                    <a href="mailto:oliviajtl14@gmail.com">oliviajtl14@gmail.com</a>
                    <Link to="https://www.instagram.com/olivialahaye/" target="_blank" rel="noreferrer">Instagram</Link>
                    <Link to="https://www.linkedin.com/in/olivia-lahaye-a924591b8/" target="_blank" rel="noreferrer">LinkedIn</Link>
                    <Link to="https://vimeo.com/user105743716" target="_blank" rel="noreferrer">Vimeo</Link>
                </div>
            </motion.div>
        // </Layout>
    );
}
  
export default Contact;