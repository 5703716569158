import React, { useState } from "react";
import Header from "./Header";
import Lottie from "lottie-react";

import reel from "../assets/reel.mp4";
import loadingAnimation from "../images/svg/loading-bar.json";

import "../styles/website.scss";
import "../styles/page.scss";


const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const lottieOptions = {
    speed: 0.1,
  }

  return (
    <main className="layout">
        <video src={reel} className={loading ? "hide" : "background-video"} autoPlay="autoplay" playsInline="playsinline" loop={true} muted={true} onCanPlayThrough={() => setLoading(false)} />
        <div className={loading ? "background-video loading-background" : "hide"}>
          <Lottie className="loading-annimation" animationData={loadingAnimation} options={lottieOptions}/>
        </div>
        <div className={loading ? "hide" : "content"}>
            <div className="content-flex">
                <Header />
                {children}
            </div>
        </div>
    </main>
  )
}

export default Layout