import React from "react";
import { useStateContext } from "../context/StateContext.js";

import "../styles/header.scss";

const Header = () => {
  const { setMenu } = useStateContext();

  return (
    <>
      <header className="header">
          <nav>
            <button onClick={() => setMenu("home")}>OLIVIA LAHAYE</button>
            <button onClick={() => setMenu("work")}>WORK</button>
            <button onClick={() => setMenu("about")}>ABOUT</button>
            <button onClick={() => setMenu("contact")}>CONTACT</button>
          </nav>
      </header>
    </>
  );
};

export default Header;
