import { Link } from "react-router-dom";

const Tag = () => {
    const tagStyle = {
            fontSize: '15px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            opacity: '0.3',
            padding: '15px',
            position: 'absolute',
            right: '0',
            bottom: '0',
        };

    const site = "https://wooliston.com/";

  return (
    <Link to={site} style={tagStyle}  target="_blank" rel="noopener noreferrer">
        <span>website built by wooliston.com</span>
    </Link>
  )
}

export default Tag