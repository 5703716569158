import {motion} from "framer-motion";

import "../styles/about.scss";


function About() {

    return (
        // <Layout>
            <motion.div
                className="main-section about"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div className="about-more">
                    <div>Paris & London based.</div>
                    <div>Specialising in film, television & photography production.</div>
                </div>
            </motion.div>
        // </Layout>
    );
}
  
export default About;