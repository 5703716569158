import { useStateContext } from "../context/StateContext.js";
import Layout from "../components/Layout";

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Work from "./Work";
import Tag from "../components/Tag";

import "../styles/home.scss";

function Static() {
    const { menu } = useStateContext();

    return (
        <Layout>
            {
                menu === "home" ? <Home />
                : menu === "about" ? <About />
                : menu === "work" ? <Work />
                : menu === "contact" ? <Contact />
                : ""
            }
            {
                menu === "about" ? <Tag />
                : menu === "work" ? <Tag />
                : menu === "contact" ? <Tag />
                : ""
            }
        </Layout>
    );
}
  
export default Static;